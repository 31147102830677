import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { trpc } from "@calcom/trpc/react";
import { Button, Form, Label, Select, showToast, Switch, TextAreaField, TextField } from "@calcom/ui";

import { formatNumber, formatPhoneNumber } from "@lib/practice/helper";

import { DollarIcon, MailIcon, MobilePhoneIcon } from "@components/Icons";
import PaymentSuccessDialog from "@components/patients/PaymentSuccessDialog";

type FormValues = {
  amount: number;
  email: string;
  phone: string;
  onEmail: boolean;
  onText: boolean;
  message: string;
  subject: string;
  discountAmount: number;
  discountPercentage: number;
};

type Props = {
  patientId?: number;
  officeId: number;
  officename?: string;
};

export default function PaymentRequest({ patientId, officeId, officename }: Props) {
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [discountOption, setDiscountOption] = useState("dollar");

  const [resultPrice, setResultPrice] = useState(0);

  const [search, setSearch] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [patient, setPatient] = useState<number | undefined>(patientId);
  const [paymentSuccessDialogOpen, setPaymentSuccessDialogOpen] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      email: "",
      phone: "",
      onEmail: true,
      onText: true,
      subject: "We're kindly requesting payment",
      discountAmount: 0,
      discountPercentage: 0,
    },
    resolver: zodResolver(
      z
        .object({
          amount: z.number().min(0.01),
          email: z.string().email().or(z.literal("")),
          phone: z.string().or(z.literal("")),
          onEmail: z.boolean(),
          onText: z.boolean(),
          message: z.string().min(1, "Required"),
          subject: z.string().min(1, "Required"),
          discountAmount: z.number().optional(),
          discountPercentage: z.number().optional(),
        })
        .superRefine((values, ctx) => {
          if (values.onEmail && !values.email) {
            ctx.addIssue({
              message: "Please enter valid email address",
              code: z.ZodIssueCode.custom,
              path: ["email"],
            });
          }
          if (values.onText && !values.phone) {
            ctx.addIssue({
              message: "Please enter valid phone number",
              code: z.ZodIssueCode.custom,
              path: ["phone"],
            });
          }
          if (!values.onText && !values.onEmail) {
            ctx.addIssue({
              message: "Either phone or email should be filled in.",
              code: z.ZodIssueCode.custom,
              path: ["email"],
            });
          }
        })
    ),
  });

  const { register, formState, control, setValue, watch } = form;

  const watchShowAmount = watch("amount");
  const watchOnEmail = watch("onEmail");
  const watchOnText = watch("onText");
  const watchDiscountAmount = watch("discountAmount");
  const watchDiscountPercentage = watch("discountPercentage");

  const sendRequest = trpc.viewer.patients.sendNotification.useMutation({
    onSuccess: (data) => {
      setLoading(false);
      if (data && data.email.on) {
        showToast(data.email.status ? "Email sent" : "Email failed", data.email.status ? "success" : "error");
      }
      if (data && data.sms.on) {
        showToast(data.sms.status ? "SMS sent" : "SMS failed", data.sms.status ? "success" : "error");
      }
      if (!data) {
        showToast("Error occured", "error");
      }
    },
    onError: (e) => {
      setLoading(false);
      showToast("Error occured", "error");
    },
  });

  const { data: patientOptions } = trpc.viewer.patients.getNameList.useQuery({
    patientName: debouncedSearchQuery,
    patientId,
    officeId,
  });

  const { data: singlePatient } = trpc.viewer.patients.getById.useQuery(
    {
      billingOfficeId: officeId,
      patientId: Number(patient),
    },
    { enabled: !!patient }
  );

  const handleSubmit = (data: FormValues) => {
    if (!patient) {
      showToast("Please select patient", "error");
      return;
    }
    setLoading(true);
    sendRequest.mutate({
      ...data,
      billingOfficeId: officeId,
      patientId: patient,
    });
  };

  const handleCopyLink = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(`${process.env.NEXT_PUBLIC_WEBAPP_URL}/${officename}/payment-link`);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  useEffect(() => {
    setResultPrice(watchShowAmount);

    if (watchDiscountAmount > 0) {
      setResultPrice(watchShowAmount - watchDiscountAmount);
    } else if (watchDiscountPercentage > 0) {
      setResultPrice(watchShowAmount - (watchShowAmount / 100) * watchDiscountPercentage);
    }
  }, [watchShowAmount, watchDiscountAmount, watchDiscountPercentage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(search);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    setValue("amount", singlePatient?.balance || 0);
    setValue("email", singlePatient?.email || "");
    setValue("phone", formatPhoneNumber(singlePatient?.phoneNumbers[0]?.number || ""));
    setValue("onEmail", singlePatient?.email ? true : false);
    setValue("onText", singlePatient?.phoneNumbers[0]?.number ? true : false);
  }, [singlePatient]);

  useEffect(() => {
    if (watchShowAmount >= 0)
      setValue(
        "message",
        `Hi ${singlePatient?.firstName || "Patient"} ${
          singlePatient?.lastName || "Name"
        }, please pay your balance of $${watchShowAmount} at your earliest convenience. Thank you!`
      );
  }, [watchShowAmount, singlePatient]);

  return (
    <div>
      <Form className="w-full" form={form} handleSubmit={handleSubmit}>
        <div className="space-y-3">
          <div className="mt-6 flex flex-col gap-5">
            <div className="">
              <Label>Account</Label>
              <div className="relative w-full">
                <Select
                  placeholder="Search Account"
                  className="rounded-xl"
                  isDisabled={!!patientId}
                  value={patientOptions?.find((p) => p.value === patient)}
                  options={patientOptions}
                  onInputChange={(e) => setSearch(e)}
                  onChange={(option) => {
                    if (option) {
                      setPatient(option.value);
                    }
                  }}
                />
              </div>
            </div>
            <div className="">
              <Label>Amount</Label>
              <TextField
                type="number"
                className="relative rounded-lg"
                label=""
                step={0.01}
                min={0}
                addOnLeading={<DollarIcon />}
                {...register("amount", { valueAsNumber: true })}
              />
            </div>
            {/* <div>
              <Label className="mb-2 pb-3">Discount</Label>
              <RadioGroup.Root
                className="space-y-2"
                value={discountOption}
                onValueChange={(value) => {
                  setDiscountOption(value);
                  if (value == "dollar") {
                    setValue("discountPercentage", 0);
                  } else {
                    setValue("discountAmount", 0);
                  }
                }}>
                <div className="flex items-center gap-8">
                  <div className="flex items-center">
                    <RadioGroup.Item
                      id="dollar"
                      value="dollar"
                      className="min-w-4 flex h-4 w-4 cursor-pointer items-center rounded-full border border-blue-500 bg-white focus:border-2 focus:outline-none ltr:mr-2 rtl:ml-2">
                      <RadioGroup.Indicator className="relative flex h-4 w-4 items-center justify-center after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-500" />
                    </RadioGroup.Item>
                    <Label htmlFor="dollar" className="!m-0 flex items-center">
                      Dollar Amount
                    </Label>
                  </div>

                  <div className="flex items-center">
                    <RadioGroup.Item
                      id="percentage"
                      value="percentage"
                      className="min-w-4 flex h-4 w-4 cursor-pointer items-center rounded-full border border-blue-500 bg-white focus:border-2 focus:outline-none ltr:mr-2 rtl:ml-2">
                      <RadioGroup.Indicator className="relative flex h-4 w-4 items-center justify-center after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-500" />
                    </RadioGroup.Item>
                    <Label htmlFor="percentage" className="!m-0 flex items-center">
                      Percentage Off
                    </Label>
                  </div>
                </div>
              </RadioGroup.Root>
              <div className="mt-4">
                {discountOption === "dollar" ? (
                  <TextField
                    type="number"
                    label=""
                    {...register("discountAmount", { valueAsNumber: true })}
                    addOnLeading={<DollarIcon />}
                  />
                ) : (
                  <TextField
                    type="number"
                    label=""
                    {...register("discountPercentage", { valueAsNumber: true })}
                    addOnLeading="%"
                  />
                )}
              </div>
            </div> */}
            <div className="">
              <Controller
                name="onEmail"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex items-center justify-between">
                    <Label className="m-0 text-gray-600">Email Address</Label>
                    <Switch label="" checked={value} onCheckedChange={onChange} />
                  </div>
                )}
              />
              <div className="relative">
                <TextField
                  label=""
                  disabled={!watchOnEmail}
                  className="w-full"
                  addOnLeading={<MailIcon />}
                  {...register("email")}
                />
              </div>
            </div>
            <div className="">
              <Controller
                name="onText"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex items-center justify-between">
                    <Label className="m-0 text-gray-600">Text Message</Label>
                    <Switch label="" checked={value} onCheckedChange={onChange} />
                  </div>
                )}
              />
              <div className="relative">
                <TextField
                  label=""
                  disabled={!watchOnText}
                  className="w-full"
                  addOnLeading={<MobilePhoneIcon />}
                  {...register("phone")}
                  placeholder="(555) 555-5555"
                  onChange={(e) => setValue("phone", formatPhoneNumber(e.target.value))}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 md:col-span-3">
              <TextField
                label="Subject Line"
                className="w-full"
                disabled={!watchOnEmail}
                {...register("subject")}
              />
            </div>
            <div className="flex flex-col gap-2 md:col-span-1">
              <TextAreaField label="Message Content" rows={5} className="w-full" {...register("message")} />
            </div>
            <Button
              className="flex h-10 justify-center bg-black text-white hover:bg-black hover:text-white"
              type="submit"
              loadingTextClassName="text-black"
              loading={loading}>
              Send ${formatNumber(watchShowAmount, "balance")} Payment Request
            </Button>
            <div className="w-full">
              <Button
                className="flex w-full justify-center border border-gray-200 bg-white text-black hover:bg-white hover:text-black"
                onClick={handleCopyLink}>
                {isCopied ? "Copied" : "Copy Payment Link"}
              </Button>
              <p className="mt-3 text-xs leading-5 text-gray-600">
                Click &apos;Copy the Payment Link&apos; and paste it into the message above when sending a
                payment request if you&apos;d like to include a payment link for this request.
              </p>
            </div>
          </div>
        </div>
      </Form>

      <PaymentSuccessDialog
        title="Payment Request Sent"
        description="Thanks for your payment, your payment was successful."
        amount={watchShowAmount}
        account={singlePatient?.firstName + " " + singlePatient?.lastName}
        date={new Date().toLocaleDateString()}
        source="Payment Link"
        open={paymentSuccessDialogOpen}
        handler={() => {
          setPaymentSuccessDialogOpen(false);
          setValue("amount", 0);
        }}
        email={singlePatient?.email || ""}
        phone={singlePatient?.phoneNumbers[0].number || ""}
        footer={
          <p className="text-center text-sm text-gray-500">
            Payment Request has been sent to {singlePatient?.email}, and{" "}
            {singlePatient?.phoneNumbers[0].number}
          </p>
        }
      />
    </div>
  );
}
