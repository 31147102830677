import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { trpc } from "@calcom/trpc";
import { Select } from "@calcom/ui";
import { FiSearch } from "@calcom/ui/components/icon";

import { LoadingIcon } from "@components/Icons";
import { useOfficeContext } from "@components/navbar/OfficeProvider";

export default function Search() {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const { setCurrentOffice, currentOffice } = useOfficeContext();

  const { data: patients, isFetching: isPatientSearching } =
    trpc.viewer.patients.searchByNameAndOffice.useQuery(
      {
        patientName: debouncedSearchQuery,
        officeId: currentOffice,
      },
      { enabled: debouncedSearchQuery.length >= 3 }
    );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  useEffect(() => {
    setSearchQuery("");
    setDebouncedSearchQuery("");
  }, [currentOffice]);

  return (
    <div className="relative flex w-full items-center md:w-[400px]">
      <Select
        instanceId="patient-search"
        placeholder="Search by Account Name or Identifier"
        hasIcon={true}
        hideDropdown={false}
        options={patients || []}
        onChange={(option) => {
          if (option) {
            setCurrentOffice(option.officeId);
            router.push(`/patients/${option.value}`);
          }
        }}
        onInputChange={(value) => setSearchQuery(value)}
        styles={{
          menu: (defaultStyles, state) => ({
            ...defaultStyles,
            marginTop: "15px",
          }),
        }}
      />
      <FiSearch className="absolute left-2 h-4 w-4 cursor-pointer text-gray-500" />
      {isPatientSearching && (
        <div className="absolute right-2 -translate-y-1/2 transform">
          <LoadingIcon />
        </div>
      )}
    </div>
  );
}
