export type Identity = {
  id: string;
  tags: { [key: string]: string };
};

export enum OPERATION_KEY {
  CARD_PRESENT_DEBIT = "CARD_PRESENT_DEBIT",
}

export type Instrument = {
  id: string;
  currency: string;
  bin: string;
  brand: string;
  last_four: string;
  expiration_month: number;
  expiration_year: number;
  instrument_type: string; // PAYMENT_CARD || BANK_ACCOUNT
  masked_account_number: string;
  account_type: string;
  bank_account_validation_check: string;
  bank_code: string;
  country: string;
  card_type: string;
};

export type Transfer = {
  id: string;
  amount: number;
  failure_code: string;
  failure_message: string;
  fee: number;
  state: string;
  card_present_details: {
    masked_account_number: string;
    brand: string;
  };
  device: string;
  type: string;
  created_at: string;
  ready_to_settle_at: string | null;
  parent_transfer: string | null;
};

export type Settlement = {
  created_at: string;
  updated_at: string;
  merchant_id: string;
  net_amount: number;
  status: string;
  total_amount: number;
  total_fee: number;
  id: string;
};

export type SettlementEntrie = {
  id: string;
  created_at: string;
  amount: number;
  entity_id: string;
  entity_type: string;
  subtype: string;
  parent_entity_id: string;
  parent_entity_type: string;
  ready_to_settle_at: string;
};

export type IdentitySearchResponse = {
  _embedded: {
    identities: Array<Identity>;
  };
};

export type IdentitySearchParam = {
  firstName: string;
  lastName: string;
  officeId: number;
  patientId: number;
};

export type IdentityCreateParam = {
  firstName?: string | null;
  lastName?: string | null;
  birthday?: string | null;
  email?: string | null;
  phone?: string | null;
  officeId: number;
  patientId: number;
};

export type InstrumentCreateParam = {
  token: string;
  identity: string;
};

export type TransferCreateParam = {
  merchant: string | null;
  amount: number;
  idempotencyId?: string;
  source?: string;
  sessionId?: string;
  device?: string;
  operationKey?: OPERATION_KEY;
  tags?: any;
  surChargeAmount?: number;
};

export type RefundCreateParam = {
  amount: number;
  idempotencyId: string;
  transferId: string;
};

export type ListSettlementTransferParam = {
  settlement_id: string;
};

export type ListSettlementTransferResponse = {
  _embedded: {
    transfers: Transfer[];
  };
};

export type ListSettlementEntriesResponse = {
  _embedded: {
    settlement_entries: SettlementEntrie[];
  };
};

export type ListSettlementsParam = {
  merchant_id: string;
  updated_at_gte?: string;
  after_cursor?: string;
  status?: string;
  page?: number;
  size?: number;
  application_id?: string;
  created_at_gte?: string;
  created_at_lte?: string;
};

export type SettlementResponse = {
  _embedded: {
    settlements: Settlement[];
  };
  _links: {
    first: {
      href: string;
    };
    self: {
      href: string;
    };
    prev: {
      href: string;
    };
    next: {
      href: string;
    };
    last: {
      href: string;
    };
  };
  page: {
    offset: number;
    limit: number;
    count: number;
    next_cursor: string | null;
  };
};

export type WebHookResponse = {
  id: string;
  type: string;
  entity: string;
  occurred_at: string;
  _embedded: {
    [key: string]: { [key: string]: string | number | null }[];
  };
};

type CalculateFeeProps = {
  amount: number;
  isSelfMerchant: boolean;
  isACHPayment: boolean;
};

const baseUrl = process.env.FINIX_API_BASE_URL;
const userName = process.env.FINIX_API_USER_NAME;
const password = process.env.FINIX_API_PASSWORD;

export const token = btoa(`${userName}:${password}`);

export const calculateFee = ({ amount, isSelfMerchant, isACHPayment }: CalculateFeeProps) => {
  let fee = 0;
  if (isACHPayment) {
    const temp = amount * 0.008;
    fee = temp > 500 ? 500 : temp;
  } else if (isSelfMerchant) {
    fee = amount * 0.0289 + 30;
  } else {
    fee = amount * 0.0289 + 50;
  }

  return Math.ceil(fee); // finix ceil fee
};

export const getIdentity = async (param: IdentitySearchParam): Promise<Identity[]> => {
  const { firstName, lastName, officeId, patientId } = param;
  const url = `identities?first_name=${firstName}&last_name=${lastName}&tags.key=patientId&tags.value=${patientId}`;

  try {
    const response = await fetch(`${baseUrl}/${url}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
    });

    if (response.ok) {
      const result: IdentitySearchResponse = await response.json();

      if (result._embedded) {
        const identities = result._embedded.identities;
        return identities.filter((id) => id.tags["officeId"] == officeId.toString());
      }

      return [];
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
  }

  return Promise.reject({ message: "Get Identity Error" });
};

export const createIdentity = async (param: IdentityCreateParam): Promise<Identity> => {
  const { firstName, lastName, email, phone, officeId, patientId } = param;

  try {
    const response = await fetch(`${baseUrl}/identities`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
      body: JSON.stringify({
        entity: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
        },
        tags: {
          patientId: patientId,
          officeId: officeId,
        },
      }),
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
  }

  return Promise.reject({ message: "Create Identity Error" });
};

export const createPaymentInstrument = async (param: InstrumentCreateParam): Promise<Instrument> => {
  const { token: paymentToken, identity } = param;

  try {
    const response = await fetch(`${baseUrl}/payment_instruments`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
      body: JSON.stringify({
        token: paymentToken,
        type: "TOKEN",
        identity: identity,
      }),
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
  }

  return Promise.reject({ message: "Create Instrument Error" });
};

export const createTransfer = async (param: TransferCreateParam): Promise<Transfer> => {
  const { amount, idempotencyId, merchant, source, sessionId, device, operationKey, tags, surChargeAmount } =
    param;
  try {
    const response = await fetch(`${baseUrl}/transfers`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
      body: JSON.stringify({
        amount: amount,
        currency: "USD",
        merchant,
        source: source || null,
        fraud_session_id: sessionId || null,
        idempotency_id: idempotencyId || null,
        device: device || null,
        operation_key: operationKey || null,
        tags: tags || null,
        additional_buyer_charges: surChargeAmount
          ? {
              surcharge_amount: surChargeAmount,
            }
          : null,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const result = await response.json();
      console.log("TRANSACTION ERROR: ", merchant, source, " - ", result._embedded.errors[0].message);
      return Promise.reject({ failure_message: result._embedded.errors[0].message });
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
    return Promise.reject({ failure_message: "Some error" });
  }
};

export const createRefund = async (param: RefundCreateParam): Promise<Transfer> => {
  const { amount, idempotencyId, transferId } = param;

  try {
    const response = await fetch(`${baseUrl}/transfers/${transferId}/reversals`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
      body: JSON.stringify({
        refund_amount: amount,
        currency: "USD",
        idempotency_id: idempotencyId,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const result = await response.json();
      console.log("TRANSACTION ERROR: ", transferId, " - ", result._embedded.errors[0].message);
      return Promise.reject({ failure_message: result._embedded.errors[0].message });
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
    return Promise.reject({ failure_message: "Some error" });
  }
};

export const listSettlements = async (param: ListSettlementsParam): Promise<SettlementResponse> => {
  const { merchant_id, created_at_gte, created_at_lte, status } = param;

  let url = `${baseUrl}/settlements?merchant_id=${merchant_id}&sort=created_at,desc&sort=id,desc`;

  if (created_at_gte && created_at_lte) {
    url += `&created_at.gte=${created_at_gte}&created_at.lte=${created_at_lte}`;
  }
  if (status) {
    url += `&status=${status}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
  }
  return Promise.reject({ message: "Error" });
};

export const listSettlementTransfers = async (
  param: ListSettlementTransferParam
): Promise<ListSettlementTransferResponse> => {
  const { settlement_id } = param;

  const url = `${baseUrl}/settlements/${settlement_id}/transfers`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
  }

  return Promise.reject({ message: "Error" });
};

export const listSettlementEntries = async (
  param: ListSettlementTransferParam
): Promise<ListSettlementEntriesResponse> => {
  const { settlement_id } = param;

  const url = `${baseUrl}/settlements/${settlement_id}/entries`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
  }

  return Promise.reject({ message: "Error" });
};

export const getSettlement = async (param: { settlement_id: string }): Promise<Settlement> => {
  const { settlement_id } = param;

  const url = `${baseUrl}/settlements/${settlement_id}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
        "Finix-Version": "2022-02-01",
      },
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (e: any) {
    console.log("ERROR", e?.message);
  }

  return Promise.reject({ message: "Error" });
};
