import type { Brands } from "./getCardBrand";
import type {
  BillingMessageTemplate,
  TransactionStatus,
  MessageSource,
  MessageLogType,
  PaymentSource,
} from ".prisma/client";
import { PracticeManagement } from ".prisma/client";

export const formatPMS = (pms: PracticeManagement) => {
  switch (pms) {
    case PracticeManagement.CURVE:
      return "Curve";
    case PracticeManagement.OPENDENTAL:
      return "OpenDental";
    case PracticeManagement.OPENDENTAL_NI:
      return "OpenDental NI";
    case PracticeManagement.WINOMS:
      return "WinOMS";
    case PracticeManagement.WINOMS_SPECIAL:
      return "WinOMS Special";
    case PracticeManagement.DENTRIX:
      return "Dentrix";
    case PracticeManagement.DENTRIX_NI:
      return "Dentrix NI";
    case PracticeManagement.DENTRIX_ASCEND:
      return "Dentrix Ascend";
    case PracticeManagement.EAGLESOFT:
      return "Eaglesoft";
    case PracticeManagement.EAGLESOFT_NI:
      return "Eaglesoft NI";
    case PracticeManagement.FUSE:
      return "Fuse";
    case PracticeManagement.DSN:
      return "DSN";
    case PracticeManagement.DENTICON:
      return "Denticon";
    case PracticeManagement.SOFTDENT:
      return "Softdent";
    case PracticeManagement.SENSI_CLOUD:
      return "Sensi Cloud";
    case PracticeManagement.DNS:
      return "DNS";
    case PracticeManagement.DENTIMAX:
      return "Dentimax";
    case PracticeManagement.QSI:
      return "QSI";
    default:
      return "Other";
  }
};

export const formatTemplate = (template: BillingMessageTemplate) => {
  switch (template) {
    case "END_TEMPLATE":
      return "End Template";
    case "START_TEMPLATE":
      return "Start Template";
    case "FINAL_REMINDER_TEMPLATE":
      return "Final Reminder Template";
    case "REMINDER_TEMPLATE":
      return "Reminder Template";
    default:
      return "";
  }
};

export const formatPaymentSource = (source: PaymentSource | null) => {
  switch (source) {
    case "AUTOMATEDAR":
      return "Auto Collection";
    case "MEMBERSHIP":
      return "Membership";
    case "ONLINE":
      return "Online";
    case "PAYMENTPLAN":
      return "Payment Plan";
    case "TERMINAL":
      return "Terminal";
    case "VIRTUAL":
      return "Virtual";
    default:
      return "";
  }
};

export const formatTransactionStatus = (status: TransactionStatus) => {
  switch (status) {
    case "FAILED":
      return "Failed";
    case "REFUND":
      return "Refund";
    case "SUCCESSFUL":
      return "Successful";
    default:
      return "";
  }
};

export const formatMessageSource = (source: MessageSource | null) => {
  switch (source) {
    case "AUTOMATEDAR":
      return "Auto Collection";
    case "CARD_REQUEST":
      return "Card Request";
    case "CONFIRMATION":
      return "Confirmation";
    case "MEMBERSHIP":
      return "Membership";
    case "PAYMENTPLAN":
      return "Payment Plan";
    case "PAYMENT_REQUEST":
      return "Payment Request";
    default:
      return "N/A";
  }
};

export const formatMessageLogType = (source: MessageLogType | null) => {
  switch (source) {
    case "EMAIL":
      return "Email";
    case "SMS":
      return "SMS";
    default:
      return "N/A";
  }
};

export const formatCardBrand = (brand: Brands | null) => {
  switch (brand) {
    case "AMERICAN_EXPRESS":
      return "American Express";
    case "MASTERCARD":
      return "Mastercard";
    case "DINERS_CLUB_INTERNATIONAL":
      return "Diners Club INternational";
    case "DANKORT":
      return "Dankort";
    case "RUPAY":
      return "Rupay";
    case "VISA":
      return "Visa";
    case "DISCOVER":
      return "Discover";
    case "INTERPAYMENT":
      return "Interpayment";
    default:
      return "N/A";
  }
};
