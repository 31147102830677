import { useEffect, useRef, useState } from "react";

import { Button } from "@calcom/ui";

type PaymentFormProps = {
  appId?: string;
  env?: string;
  buttonBackColor?: string | null;
  handlePayment: (token: string) => void;
  buttonText: string;
  isDisabled?: boolean;
  isPaymentProcessing?: boolean;
  enableACH?: boolean;
  setIsACHPayment?: (v: boolean) => void;
};

const PaymentForm = (props: PaymentFormProps) => {
  const {
    appId,
    buttonBackColor,
    buttonText,
    env = "sandbox",
    handlePayment,
    isDisabled = false,
    isPaymentProcessing,
    enableACH,
    setIsACHPayment,
  } = props;
  const formRef = useRef(null);
  const [formHasError, setFormHasError] = useState(true);

  const handleSubmit = async () => {
    if (formRef.current) {
      formRef.current.submit(env, appId, (err, res) => {
        // get token ID from response
        const tokenData = res.data || {};
        const token = tokenData.id;

        handlePayment(token);
      });
    }
  };

  const options = {
    showAddress: true,
    showLabels: true,
    hideFields: [
      "name",
      "address_line1",
      "address_line2",
      "address_city",
      "address_state",
      "address_region",
      "address_country",
    ],
    requiredFields: ["address_postal_code"],
    styles: {
      default: {
        color: "#000",
        border: "1px solid #CCCDCF",
        borderRadius: "6px",
        padding: "8px 12px",
        fontSize: "14px",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03)",
      },
    },
    onUpdate: function (state, binInformation, formHasErrors) {
      setFormHasError(formHasErrors);
    },
    submitLabel: buttonText,
  };

  useEffect(() => {
    if (enableACH) formRef.current = window.Finix.TokenForm("card-form", options);
    else formRef.current = window.Finix.CardTokenForm("card-form", options);
  }, [enableACH]);

  useEffect(() => {
    document.querySelector("#finix-bank-button")?.addEventListener("click", () => {
      console.log("ACH Payment selected");
      if (setIsACHPayment) setIsACHPayment(true);
    });
    document.querySelector("#finix-card-button")?.addEventListener("click", () => {
      console.log("Card Payment selected");
      if (setIsACHPayment) setIsACHPayment(false);
    });

    if (setIsACHPayment) setIsACHPayment(false);
  }, []);

  return (
    <>
      <div id="card-form" style={{ width: "100%" }} />
      <Button
        loadingTextClassName="text-black"
        className="mt-4 h-10 w-full justify-center bg-black p-3 text-sm font-bold hover:bg-black"
        disabled={formHasError || isDisabled}
        loading={isPaymentProcessing}
        onClick={handleSubmit}>
        {buttonText}
      </Button>
    </>
  );
};

export default PaymentForm;
