import { UserPermissionRole } from "@prisma/client";
import { signOut } from "next-auth/react";
import React, { useState } from "react";

import { trpc } from "@calcom/trpc/react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@calcom/ui";
import { BellIcon, NoteIcon, UserIcon } from "@calcom/web/components/Icons";
import { ReportDialog } from "@calcom/web/components/dialog/ReportDialog";

import { CreateOfficeDialog } from "@components/dialog/OfficeDialog";
import { useOfficeContext } from "@components/navbar/OfficeProvider";
import OfficeSelect from "@components/navbar/OfficeSelect";
import Search from "@components/navbar/Search";
import CreatePaymentDialog from "@components/patients/CreatePaymentDialog";

const appId = process.env.FINIXAPPID || "";
const env = process.env.FINIXENV || "";

const Profile = () => {
  return (
    <Dropdown modal={false}>
      <DropdownMenuTrigger asChild data-testid="event-type-options-">
        <div className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg border border-gray-200 p-2">
          <UserIcon />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        style={{
          marginTop: "10px",
          width: "150px",
        }}>
        {/* <DropdownMenuItem>
          <DropdownItem type="button" data-testid="event-type-edit-">
            Edit Profile
          </DropdownItem>
        </DropdownMenuItem> */}
        <DropdownMenuItem className="rounded-lg" onClick={() => signOut({ callbackUrl: "/auth/logout" })}>
          <DropdownItem type="button" data-testid="event-type-edit-" className="rounded-lg">
            Log Out
          </DropdownItem>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </Dropdown>
  );
};

function TopNavbar() {
  const { data: user, isLoading } = trpc.viewer.me.useQuery();

  const [reportDlgOpen, setReportDlgOpen] = useState(false);
  const [createPaymentDlg, setCreatePaymentDlg] = useState(false);
  const [officeDlgOpen, setOfficeDlgOpen] = useState(false);

  const { currentOffice } = useOfficeContext();

  return (
    <div className="fixed top-0 z-10 h-[52px] w-full border-b border-gray-200 bg-white">
      <div className="absolute h-full w-52 bg-white md:bg-[#F9FAFA]" />
      <div className="absolute left-1/2 h-full w-1/2 bg-white" />
      <div className="relative mx-auto h-[52px] w-full flex-col items-center md:flex md:flex-row md:gap-0 md:p-0">
        <div className="flex border-r border-gray-200 md:mb-0 md:flex-row">
          <div className="flex h-[52px] w-[250px] items-center gap-3 px-3 py-2 md:w-[207px]">
            {!isLoading && !user?.isGroupView && (
              <div>
                <img src={user?.logo || ""} alt="billing logo" className="h-7" />
                <div className="hidden">
                  <OfficeSelect />
                </div>
              </div>
            )}
            {!isLoading && user?.isGroupView && !currentOffice && (
              <img src={user.logo || ""} alt="billing logo 1" className="h-7" />
            )}
            {!isLoading && user?.isGroupView && !!currentOffice && <OfficeSelect />}
          </div>
          <div className="flex-grow" />
          <div className="my-1 flex items-center gap-4 py-2 pr-4 md:hidden md:pr-0">
            {/* <div className="flex h-9 w-9 items-center justify-center rounded-lg border border-gray-200 p-2">
              <HelpIcon />
            </div> */}
            <div className="flex h-9 w-9 items-center justify-center rounded-lg border border-gray-200 p-2">
              <BellIcon />
            </div>
            <Profile />
          </div>
        </div>
        <div className="px-4 py-2 md:hidden">
          <Button
            className="flex h-9 w-full justify-center rounded-lg border border-r-gray-200 bg-white text-gray-600 hover:bg-white hover:text-gray-600"
            onClick={() => {
              setCreatePaymentDlg(true);
            }}>
            Create Payment
          </Button>
        </div>
        <div className="mx-auto mt-2 flex w-full max-w-7xl bg-white px-4 md:mt-0 md:px-6">
          {!!currentOffice && (
            <div className="mb-4 flex w-full items-center md:mb-0 md:w-auto">
              <Search />
            </div>
          )}
          <div className="hidden flex-grow md:block" />
          {!currentOffice && user?.role === UserPermissionRole.ADMIN && (
            <div className="mb-2 flex justify-around gap-8 md:mb-0 md:justify-start">
              <Button
                className="h-9 rounded-lg border border-r-gray-200 bg-white text-gray-600 hover:bg-white hover:text-gray-600"
                onClick={() => {
                  setOfficeDlgOpen(true);
                }}>
                Add Location
              </Button>
            </div>
          )}
          {!!currentOffice && (
            <div className="hidden items-center gap-4 md:flex">
              <Button
                className="h-9 rounded-lg border border-r-gray-200 bg-white text-gray-600 hover:bg-white hover:text-gray-600"
                onClick={() => {
                  setCreatePaymentDlg(true);
                }}>
                Create Payment
              </Button>
              <div
                className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg border border-gray-200 p-2"
                onClick={() => {
                  setReportDlgOpen(true);
                }}>
                <NoteIcon />
              </div>
              {/* <div className="flex h-9 w-9 items-center justify-center rounded-lg border border-gray-200 p-2">
                <HelpIcon />
              </div> */}
              <div className="flex h-9 w-9 items-center justify-center rounded-lg border border-gray-200 p-2">
                <BellIcon />
              </div>
              <Profile />
            </div>
          )}
        </div>
      </div>
      <ReportDialog open={reportDlgOpen} handler={setReportDlgOpen} />

      {createPaymentDlg && currentOffice && (
        <CreatePaymentDialog
          open={createPaymentDlg}
          handler={setCreatePaymentDlg}
          officeId={currentOffice}
          appId={appId}
          env={env}
        />
      )}
      {officeDlgOpen && <CreateOfficeDialog open={officeDlgOpen} handler={setOfficeDlgOpen} isNew={true} />}
    </div>
  );
}

export default TopNavbar;
