import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";

import { trpc } from "@calcom/trpc/react";
import { Button, Label, Select, TextAreaField, TextField, Form, showToast, Switch } from "@calcom/ui";

import { formatNumber } from "@lib/practice/helper";

import { DollarIcon } from "@components/Icons";
import PaymentSuccessDialog from "@components/patients/PaymentSuccessDialog";

type Props = {
  patientId?: number;
  officeId: number;
  setPaymentDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  amount: number;
  note: string;
  terminal: string;
  enableSurcharge: boolean;
  discountAmount: number;
  discountPercentage: number;
};

export default function TerminalPayment({ patientId, officeId, setPaymentDialog }: Props) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [discountOption, setDiscountOption] = useState("dollar");

  const [resultPrice, setResultPrice] = useState(0);

  const [patient, setPatient] = useState<number | undefined>(patientId);
  const [sendAmount, setSendAmount] = useState(0);

  const [paymentSuccessDialogOpen, setPaymentSuccessDialogOpen] = useState(false);

  const { data, isLoading: terminalLoading } = trpc.viewer.office.terminalList.useQuery({ officeId });
  const { data: office } = trpc.viewer.office.getById.useQuery({
    officeId: officeId,
  });

  const { data: patientData } = trpc.viewer.patients.getById.useQuery({
    patientId: patient || -99999999,
    billingOfficeId: officeId,
  });

  const terminalList = data ? data.map((d) => ({ label: d.name, value: d.deviceId })) : [];

  const form = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      enableSurcharge: false,
      discountAmount: 0,
      discountPercentage: 0,
    },
    resolver: zodResolver(
      z.object({
        terminal: z.string().min(5, "Required"),
        amount: z.number().min(0.01),
        note: z.string().optional(),
        enableSurcharge: z.boolean(),
        discountAmount: z.number().optional(),
        discountPercentage: z.number().optional(),
      })
    ),
  });
  const { register, formState, control, setValue, watch } = form;

  const watchEnableSurcharge = watch("enableSurcharge");
  const watchAmount = watch("amount");
  const watchDiscountAmount = watch("discountAmount");
  const watchDiscountPercentage = watch("discountPercentage");

  useEffect(() => {
    setResultPrice(sendAmount);

    if (watchDiscountAmount > 0) {
      setResultPrice(sendAmount - watchDiscountAmount);
    } else if (watchDiscountPercentage > 0) {
      setResultPrice(sendAmount - (sendAmount / 100) * watchDiscountPercentage);
    }
  }, [sendAmount, watchDiscountAmount, watchDiscountPercentage]);

  useEffect(() => {
    if (watchAmount === 0) {
      setSendAmount(0);
    } else if (watchEnableSurcharge) {
      setSendAmount(watchAmount + watchAmount * 0.0269);
    } else {
      setSendAmount(watchAmount);
    }
  }, [watchAmount, watchEnableSurcharge]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(search);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (data && data.length > 0) {
      const terminal = data.find((d) => d.default) || data[0];
      setValue("terminal", terminal.deviceId);
    }
  }, [data]);

  useEffect(() => {
    if (office) {
      setValue("enableSurcharge", office.applySurchargeFee && office.surchargeOnTerminalPay);
    }
  }, [office]);

  const { data: patientOptions, isLoading: patientLoading } = trpc.viewer.patients.getNameList.useQuery({
    patientName: debouncedSearchQuery,
    patientId,
    officeId,
  });

  const sendToTerminal = trpc.viewer.patients.processTerminalPayment.useMutation({
    onSuccess: (data) => {
      showToast("Successfully Paid", "success");
      setLoading(false);
      setPaymentSuccessDialogOpen(true);
      // setValue("amount", 0);
    },
    onError: (err) => {
      showToast(err.message, "error");
      setLoading(false);
    },
  });

  const handleSubmit = (data: FormValues) => {
    // if (!patient) {
    //   showToast("Please select a patient", "error");
    //   return;
    // }
    setLoading(true);
    sendToTerminal.mutate({
      patientId: patient,
      officeId,
      amount: sendAmount,
      deviceId: data.terminal,
      note: data.note,
      default: true,
    });
  };

  return (
    <div className="rounded-md bg-white text-sm text-gray-500">
      <Form form={form} handleSubmit={handleSubmit}>
        <div className="space-y-3">
          <div className="mt-6 flex flex-col gap-5">
            <div className="">
              <Label>Account</Label>
              <div className="relative w-full">
                <Select
                  placeholder="Search Account"
                  className="rounded-xl"
                  isDisabled={!!patientId}
                  value={patientOptions?.find((p) => p.value === patient)}
                  options={patientOptions}
                  onInputChange={(e) => setSearch(e)}
                  isLoading={patientLoading}
                  onChange={(option) => {
                    if (option) {
                      setPatient(option.value);
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <Label>Card Terminal</Label>
              <Controller
                name="terminal"
                render={({ field: { value, onChange } }) => (
                  <>
                    <Select
                      className="w-full"
                      instanceId="terminalid"
                      placeholder="Select Terminal"
                      options={terminalList}
                      value={terminalList.find((p) => p.value === value)}
                      onChange={(option) => option && onChange(option.value)}
                      isLoading={terminalLoading}
                    />
                  </>
                )}
              />
              {formState.errors.terminal && (
                <div className="text-gray mt-2 flex items-center gap-x-2 text-sm text-red-700">
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-3 w-3"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="16" x2="12" y2="12" />
                      <line x1="12" y1="8" x2="12.01" y2="8" />
                    </svg>
                  </div>
                  <p>Required</p>
                </div>
              )}
            </div>
            <div className="">
              <Label>Amount</Label>
              <TextField
                type="number"
                className="relative rounded-lg"
                label=""
                step={0.01}
                addOnLeading={<DollarIcon />}
                {...register("amount", { valueAsNumber: true })}
              />
            </div>
            {/* <div>
              <Label className="mb-2 pb-3">Discount</Label>
              <RadioGroup.Root
                className="space-y-2"
                value={discountOption}
                onValueChange={(value) => {
                  setDiscountOption(value);
                  if (value == "dollar") {
                    setValue("discountPercentage", 0);
                  } else {
                    setValue("discountAmount", 0);
                  }
                }}>
                <div className="flex items-center gap-8">
                  <div className="flex items-center">
                    <RadioGroup.Item
                      id="dollar"
                      value="dollar"
                      className="min-w-4 flex h-4 w-4 cursor-pointer items-center rounded-full border border-blue-500 bg-white focus:border-2 focus:outline-none ltr:mr-2 rtl:ml-2">
                      <RadioGroup.Indicator className="relative flex h-4 w-4 items-center justify-center after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-500" />
                    </RadioGroup.Item>
                    <Label htmlFor="dollar" className="!m-0 flex items-center">
                      Dollar Amount
                    </Label>
                  </div>

                  <div className="flex items-center">
                    <RadioGroup.Item
                      id="percentage"
                      value="percentage"
                      className="min-w-4 flex h-4 w-4 cursor-pointer items-center rounded-full border border-blue-500 bg-white focus:border-2 focus:outline-none ltr:mr-2 rtl:ml-2">
                      <RadioGroup.Indicator className="relative flex h-4 w-4 items-center justify-center after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-500" />
                    </RadioGroup.Item>
                    <Label htmlFor="percentage" className="!m-0 flex items-center">
                      Percentage Off
                    </Label>
                  </div>
                </div>
              </RadioGroup.Root>
              <div className="mt-4">
                {discountOption === "dollar" ? (
                  <TextField
                    type="number"
                    label=""
                    {...register("discountAmount", { valueAsNumber: true })}
                    addOnLeading={<DollarIcon />}
                  />
                ) : (
                  <TextField
                    type="number"
                    label=""
                    {...register("discountPercentage", { valueAsNumber: true })}
                    addOnLeading="%"
                  />
                )}
              </div>
            </div> */}
            {office?.applySurchargeFee && (
              <div className="md:col-span-2">
                <Controller
                  name="enableSurcharge"
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      label="Enable surcharge: Customers pay 2.69% for in-person payments."
                      checked={value}
                      onCheckedChange={onChange}
                    />
                  )}
                />
              </div>
            )}
            <div className="md:col-span-1">
              <Label>Note</Label>
              <TextAreaField
                placeholder="Note will be included with transaction details."
                rows={3}
                {...register("note")}
                label=""
              />
            </div>
            <Button
              className="flex h-10 justify-center bg-black text-white hover:bg-black hover:text-white"
              type="submit"
              loadingTextClassName="text-black"
              loading={loading}>
              Send ${formatNumber(sendAmount, "balance")} to Terminal
            </Button>
          </div>
        </div>
      </Form>
      <PaymentSuccessDialog
        title="Payment Sent to Terminal"
        description="Thanks for your payment, your payment was successful."
        amount={sendAmount}
        account={patientData?.firstName + " " + patientData?.lastName}
        date={new Date().toLocaleDateString()}
        source="Payment Link"
        open={paymentSuccessDialogOpen}
        handler={() => {
          setPaymentSuccessDialogOpen(false);
          setValue("amount", 0);
        }}
        email={patientData?.email || ""}
        phone={patientData?.phoneNumbers[0].number || ""}
        footer={
          <p className="text-center text-sm text-gray-500">
            Terminal Payment has been sent to {patientData?.firstName + " " + patientData?.lastName}
          </p>
        }
      />
    </div>
  );
}
